
import { Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { Notification } from 'element-ui';
import { AxiosRequestConfig } from 'axios';
import NotificationsContainer
    from '@/modules/common/modules/custom-notification/components/notifications-container.vue';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import ExceptionHandler, { ExceptionHandlerS } from '@/modules/common/modules/exception-handler/exception-handler.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';
import TranslationsService, { TranslationsServiceS } from '@/modules/translations/translations.service';
import CustomTooltipDirective from '@/modules/common/components/ui-kit/custom-tooltip-directive.vue';
import { USER_LEVELS } from '@/modules/user/constants';
import { routerData } from './router';
import { LOGTYPE } from './modules/open-telemetry/constants';
import TranslationsModel from './modules/translations/translations.model';

@Component({
    components: {
        NotificationsContainer,
        CustomTooltipDirective,
    },
})
export default class App extends Vue {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(NotifyServiceS) private notifyService!: NotifyService;
    @Inject(ExceptionHandlerS) private exceptionHandler!: ExceptionHandler;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(OpenTelemetryServiceS) private openTelemetryService!: OpenTelemetryService;
    @Inject(TranslationsServiceS) private translationsService!: TranslationsService;

    /**
     * Here we will connect services with vue features
     */
    created() {
        this.apiService.setRequestInterceptor(config => {
            this.$Progress.start();
            return config;
        });
        this.apiService.setResponseInterceptor(response => {
            this.$Progress.finish();
            return response;
        });

        // Set fornova-id to header for all hotel requests, undefined is sent for cluster level
        this.apiService.setRequestInterceptor((req: AxiosRequestConfig) => ({
            ...req,
            headers: {
                ...req.headers,
                'fornova-id': this.userService.viewAs === USER_LEVELS.HOTEL
                    ? this.userService.currentHotelId
                    : undefined,
            },
        }));

        this.openTelemetryService.init(routerData.router);

        this.notifyService.init({
            errorNotification: (message?: string) => {
                Notification({
                    title: message || 'ERROR',
                    message: '',
                    type: 'error',
                    showClose: false,
                    customClass: 'custom-notification error',
                });
            },
            infoNotification: (message?: string) => {
                Notification({
                    title: message || 'INFO',
                    message: '',
                    type: 'info',
                    showClose: false,
                    customClass: 'custom-notification info',
                });
            },
            successNotification: (message?: string) => {
                Notification({
                    title: message || 'SUCCESS',
                    message: '',
                    type: 'success',
                    showClose: false,
                    customClass: 'custom-notification success',
                });
            },
            warningNotification: (message?: string) => {
                Notification({
                    title: message || 'WARNING',
                    message: '',
                    type: 'warning',
                    showClose: false,
                    customClass: 'custom-notification warning',
                });
            },
        });

        Promise.all([this.translationsService.loadData(), this.userService.storeState.loading.whenLoadingFinished()])
            .then(() => {
                const { availableLocales } = this.translationsService;
                availableLocales.forEach(locale => {
                    this.$i18n.setLocaleMessage(locale, this.translationsService.getTranslations(locale));
                });

                const locale = this.userService.user!.settings.lang as keyof TranslationsModel;
                this.translationsService.setLocale(locale);

                if (this.userService.isCarUser) {
                    return;
                }

                // OTEL have to be inited with user info, here we awaits sso response
                this.$router.beforeEach((to, from, next) => {
                    this.$gtag.event('page_view', {
                        page_path: to.path,
                        chainId: this.userService.chainId,
                        userId: this.userService.id,
                    });

                    if (from.name) {
                        this.openTelemetryService.endSpan({ name: from.name, prefix: LOGTYPE.VISITPAGE }, { sendLogs: true });
                    }

                    // Avoid sending visit-page log on settings page because all tabs have same name in router
                    if (to.name !== 'settings') {
                        this.openTelemetryService.startSpan({ name: to.name!, prefix: LOGTYPE.VISITPAGE });
                    }
                    next();
                });
            });
    }

    errorCaptured(error: Error) {
        this.exceptionHandler.catch(error);
    }
}
