import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';
import RatesPriceHistoryModel from '../models/rates-price-history.model';
import RatesPriceHistoryFilterAllModel from '../models/rates-price-history-filter-all.model';

@Module
export default class RatesPriceHistoryStore extends VuexModule {
    loading: LoadingModel = new LoadingModel();

    dayIndex: number = 0;
    documentDay: number | null = null;
    lastScanDate: Date | null = null;

    // last chartJs tooltip position, to save it between price history openings
    lastTooltipPos: string | null = null;
    documentId: number | null = null;
    currency: string | null = null;
    ratesSettings: RatesSettingsModel | null = null;
    ratesDoc: RatesDocumentModel | RatesDocumentAllModel | RatesCompsetMainModel | null = null;
    documents: {
        main: RatesPriceHistoryModel | RatesPriceHistoryFilterAllModel | null,
        [key: string]: RatesPriceHistoryModel | RatesPriceHistoryFilterAllModel | null,
    } = {
        main: null,
    };
}
