/* eslint-disable no-dupe-class-members */
import { Inject, injectable } from 'inversify-props';

import type Day from '@/modules/common/types/day.type';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';

import ICheckinDates from '@/modules/rates/interfaces/checkin-dates.interface';
import ICheckinDatesHotels from '@/modules/rates/interfaces/checkin-dates-hotels.interface';

import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';
import CompsetModel from '@/modules/compsets/models/compset.model';

import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RatesApiService, { RatesApiServiceS } from '@/modules/rates/rates-api.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';

import RatesStore from '@/modules/rates/store/rates.store';
import UserStore from '@/modules/user/store/user.store';
import { SCAN_STATUS } from '@/modules/rates/constants';
import StoreFacade, { StoreFacadeS } from '../../services/store-facade';
import HotelRooms from '../../interfaces/hotelRooms.interface';
import PRICE from './constants/price.enum';
import getScanRange from '../../utils/get-scan-range.util';

export type RatesUnionDocModel = RatesDocumentModel | RatesDocumentAllModel | RatesCompsetMainModel;
export type AllChannelsCheckinDay = Exclude<Exclude<RatesDocumentAllModel['checkinDates'], undefined>[0], null>;

const filterRoomEntries = (entries: [number | string, ICheckinDatesHotels[0]][]) => {
    const newEntries = entries
        .filter(([, data]) => {
            const values = Object.values(data?.rooms || {});
            const room = values.length ? values[0][0] : null;

            return !!room;
        })
        .map(([hotelId, data]) => {
            const values = Object.values(data.rooms || {});
            const room = values[0][0];

            return [hotelId, room] as [number, RatesDocumentItemModel];
        });

    return Object.fromEntries(newEntries);
};

export const RatesCommonServiceS = Symbol.for('RatesCommonServiceS');
@injectable(RatesCommonServiceS as unknown as string)
export default class RatesCommonService {
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(RatesApiServiceS) private ratesApiService!: RatesApiService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;

    readonly storeState: RatesStore = this.storeFacade.getState('RatesStore');
    readonly userStoreState: UserStore = this.storeFacade.getState('UserStore');

    get scanLimitation() {
        return this.storeState.scanLimitation;
    }

    currency(data: RatesUnionDocModel | null) {
        return data ? (data.currency || null) : null;
    }

    async loadRemainingScans(hotelId: number | null, provider: string | null, force?: boolean) {
        if (this.userStoreState.user?.isTestUser) {
            return true;
        }

        const { provider: oldProvider, hotelId: oldHotelId } = this.storeState.scanLimitation;

        if (!hotelId || !provider) {
            return false;
        }

        if (oldProvider === provider && oldHotelId === hotelId && !force) {
            return true;
        }

        this.scanLimitation.provider = provider;
        this.scanLimitation.hotelId = hotelId;

        const remaining = await this.ratesApiService.getRemainingOndemandLimit(provider, hotelId);

        this.scanLimitation.remaining = remaining;

        return true;
    }

    redefineRankForRooms(doc: RatesDocumentModel | null) {
        if (!doc) {
            return;
        }

        const { days } = this.documentFiltersService;

        days.forEach(day => {
            const rooms = this.getAllRooms(day, doc);

            const byValidPrice = (room: RatesDocumentItemModel) => {
                const price = this.switchPrice(room);

                return true
                    && price
                    && price !== PRICE.SOLD_OUT
                    && price !== PRICE.NA;
            };

            Object
                .values(rooms)
                .reverse()
                .filter(byValidPrice)
                .sort((aRoom, bRoom) => {
                    const a = this.switchPrice(aRoom)!;
                    const b = this.switchPrice(bRoom)!;

                    return a - b;
                })
                .forEach((room, i) => {
                    // eslint-disable-next-line no-param-reassign
                    room.priceRank = i + 1;
                });
        });
    }

    getCheckinDay(day: Day, doc: RatesUnionDocModel | null) {
        if (!doc) return null;

        return doc.checkinDates
            ? doc.checkinDates[day] as ICheckinDates[0] || null
            : null;
    }

    getAllRoomsByHotels(hotels: ICheckinDatesHotels) {
        const entries = Object.entries(hotels || {}) as unknown as [number, ICheckinDatesHotels[0]][];

        return filterRoomEntries(entries);
    }

    /**
     * Returns all rooms from specified checkinDay
     * param `providerList` returns rooms in `all channels` mode
     */
    getAllRooms(day: Day, doc: RatesUnionDocModel | null) {
        const checkinDay = this.getCheckinDay(day, doc);
        if (!checkinDay) return {};

        const isAllChannels = this.isAllChannelsDocument(doc);

        if (isAllChannels) {
            const cd = checkinDay as unknown as AllChannelsCheckinDay;

            const providerList = Object
                .keys(cd)
                .filter(key => key !== 'updateDate');

            const entries = providerList
                .map(e => [e, cd[e]]) as [string, AllChannelsCheckinDay[0]][];

            return filterRoomEntries(entries);
        }

        const hotels = checkinDay.hotels as ICheckinDatesHotels;

        if (!hotels) return {};

        const entries = Object.entries(hotels) as unknown as [number, ICheckinDatesHotels[0]][];

        return filterRoomEntries(entries);
    }

    private getRooms(day: Day, hotelId: number | string, doc: RatesUnionDocModel | null) {
        const checkinDay = this.getCheckinDay(day, doc);
        if (!checkinDay) {
            return null;
        }

        if (this.isAllChannelsDocument(doc)) {
            const provider = hotelId as unknown as string;
            const cd = checkinDay as unknown as AllChannelsCheckinDay;
            const { rooms } = cd[provider] || { rooms: null };

            if (!rooms) {
                return null;
            }

            return Object.values(rooms).pop()!;
        }

        if (typeof hotelId === 'string') {
            return null;
        }

        const hotels = checkinDay.hotels as ICheckinDatesHotels;
        if (!hotels[hotelId]?.rooms) {
            return null;
        }

        return Object.values(hotels[hotelId].rooms!).pop() || null;
    }

    getRoom(day: Day, hotelId: number | string, doc: RatesUnionDocModel | null, priceShown?: PRICE_SHOWN) {
        const rooms = this.getRooms(day, hotelId, doc);

        if (!rooms) {
            return null;
        }

        if (priceShown) {
            const priceList = rooms
                .map(room => this.getRoomPrice(room, priceShown) as number)
                .filter(price => typeof price === 'number');

            const [lowestPrice] = priceList
                .sort((a, b) => (a - b))
                .sort((a, b) => ((!a || a < 0 || !b || b < 0) ? -1 : 0));

            return rooms
                .filter(room => {
                    const price = this.getRoomPrice(room, priceShown);
                    return price === lowestPrice;
                })[0] || null;
        }

        return rooms[0] || null;
    }

    getRoomPrice(room: RatesDocumentItemModel | undefined, priceShown: PRICE_SHOWN) {
        if (!room || !room.price) {
            return null;
        }

        const key = `${priceShown.toLowerCase()}Price` as keyof RatesDocumentItemModel['price'];

        return room.price[key] as number;
    }

    getTableAssessment(
        price: number,
        day: Day,
        mainHotelId: number,
        compset: CompsetModel | null,
        doc: RatesDocumentModel | null,
    ) {
        if (!compset) return null;

        const { type } = compset;
        const mainPrice = this.getPrice(day, mainHotelId, doc);

        if (mainPrice === null) return null;

        switch (type) {
            case COMPSET_TYPE.HIGH:
            case COMPSET_TYPE.MEDIAN:
                return price > mainPrice
                    ? ASSESSMENT_TYPES.GOOD
                    : ASSESSMENT_TYPES.BAD;

            default:
                return price <= mainPrice
                    ? ASSESSMENT_TYPES.GOOD
                    : ASSESSMENT_TYPES.BAD;
        }
    }

    getPrice(
        day: Day,
        hotelId: number | string,
        doc: RatesUnionDocModel | null,
        priceShown?: PRICE_SHOWN,
    ) {
        const room = this.getRoom(day, hotelId, doc, priceShown);

        if (!room) return null;

        return this.switchPrice(room, priceShown);
    }

    getPriceDay(
        hotelId: number,
        data: {[p: number]: RatesDocumentItemModel},
        priceShown?: PRICE_SHOWN,
    ) {
        if (!data) {
            return null;
        }
        return data[hotelId] ? this.switchPrice(data[hotelId], priceShown) : null;
    }

    // TODO Check that this method is used somewhere
    //
    // UPD: Used in home.service.ts i suppose it don't need there
    //      since we have `getCardAssessment` method
    calculateDay(
        day: Day,
        document: RatesUnionDocModel,
        compsetType: COMPSET_TYPE,
        mainHotelId: number,
        priceShown: PRICE_SHOWN,
        competitors?: number[] | null,
    ) {
        let rooms = this.getAllRooms(day, document);

        if (competitors && competitors.length) {
            const hotelEntries = Object
                .entries(rooms)
                .filter(([key]: [string, RatesDocumentItemModel]) => {
                    const hotelId = parseInt(key, 10);
                    return competitors.includes(hotelId);
                });

            rooms = Object.fromEntries(hotelEntries);
        }

        const competitorRooms = () => {
            const { [mainHotelId]: mainHotelRoom, ...rest } = rooms;
            return rest;
        };

        const hotelPrice = this.getPrice(day, mainHotelId, document, priceShown) as number;
        const compsetPrice = this.getCompsetPrice(competitorRooms(), compsetType, PRICE_SHOWN.SHOWN);

        if (!hotelPrice || !compsetPrice) {
            return {
                rooms,
                hotelPrice,
                compsetPrice,
                competitionPercent: null,
            };
        }

        const competitionPercent = (hotelPrice - compsetPrice) / compsetPrice;

        return {
            rooms,
            hotelPrice,
            compsetPrice,
            competitionPercent,
        };
    }

    get currentHotelId() {
        return this.userStoreState.user?.currentHotelId || null;
    }

    getCompetitorsRooms(day: Day, doc: RatesDocumentModel | null, mainHotelId?: number): HotelRooms {
        const allRooms = this.getAllRooms(day, doc);
        const hotelId = mainHotelId || this.currentHotelId;

        const { competitors } = this.documentFiltersService;

        if (!hotelId || !competitors) return {};

        const entries = competitors
            .map(hid => [hid, allRooms[hid]]);

        return Object.fromEntries(entries);
    }

    isBasic(
        day: Day,
        hotelId: number,
        data: RatesDocumentModel | null,
    ) {
        if (!data) {
            return null;
        }
        const allRooms = this.getAllRooms(day, data);
        return (allRooms && allRooms[hotelId]) ? allRooms[hotelId].isBasic : false;
    }

    isIntraday(rooms: HotelRooms) {
        if (!rooms) {
            return false;
        }

        if (!this.currentHotelId || !rooms[this.currentHotelId]) {
            return false;
        }

        return rooms[this.currentHotelId]!.intraday || false;
    }

    isAllChannelsDocument(doc: RatesUnionDocModel | null) {
        if (doc === null) {
            return false;
        }

        return doc.providerName === 'all';
    }

    getUpdateDate(
        day: Day,
        doc: RatesUnionDocModel | null,
    ) {
        if (doc === null) {
            return null;
        }

        const isAllChannelsMode = this.isAllChannelsDocument(doc);
        if (isAllChannelsMode) {
            const dateStr = this.getAllChannelsUpdateDate(day, doc as RatesDocumentAllModel);
            return dateStr ? new Date(dateStr) : null;
        }

        const cd = this.getCheckinDay(day, doc as RatesDocumentModel);

        if (!cd || !cd.updateDate) {
            return null;
        }

        return new Date(cd.updateDate);
    }

    getAllChannelsUpdateDate(
        day: Day,
        doc: RatesDocumentAllModel | null,
    ) {
        if (!doc || !doc.updateDates || !doc.updateDates[day]) {
            return null;
        }

        return doc.updateDates[day];
    }

    /**
     * **For Cheapest channel only**
     * Returns provider list for specific room
     */
    getRoomProviders(doc: RatesDocumentModel | null, day: Day, hotelId: number, priceShown: PRICE_SHOWN = PRICE_SHOWN.SHOWN) {
        const rooms = this.getRooms(day, hotelId, doc);
        const actualPriceShown = priceShown || this.documentFiltersService.priceShown;

        if (!rooms) {
            return null;
        }

        const priceList = rooms
            .map(room => this.getRoomPrice(room, actualPriceShown))
            .filter(price => !!price) as number[];

        const [lowestPrice] = priceList
            .sort((a, b) => (a - b))
            .sort((a, b) => ((!a || a < 0 || !b || b < 0) ? -1 : 0));

        return rooms
            .filter(room => {
                const price = this.getRoomPrice(room, actualPriceShown);
                if (price === null) {
                    return false;
                }
                return price === lowestPrice;
            })
            .map(room => room!.provider!);
    }

    switchPrice(room: RatesDocumentItemModel | null, priceShown?: PRICE_SHOWN) {
        if (!room || !room.price) {
            return null;
        }

        const ps = priceShown || this.documentFiltersService.priceShown;
        const price = room.price as { [k: string]: number };

        const key = `${ps.toLowerCase()}Price`;

        return price[key];
    }

    calculateDiff(
        mainPrice: number | null,
        comparisonPrice: number | null,
        asPercent: boolean,
    ) {
        if (!mainPrice || !comparisonPrice) {
            return null;
        }

        const diff = mainPrice - comparisonPrice;
        const percent = diff / comparisonPrice;
        return !asPercent ? diff : percent;
    }

    getCompsetPrice(rooms: HotelRooms | null, compsetType: COMPSET_TYPE, priceShown?: PRICE_SHOWN) {
        if (!rooms) return null;

        const competitorPrices = Object
            .values(rooms)
            .filter(e => !!e)
            .map(room => this.switchPrice(room, priceShown));

        // TODO: Should be moved into filters script
        const byValidPrices = (price: number | null) => true
            && price !== null
            && price !== PRICE.SOLD_OUT
            && price !== PRICE.NA;

        const filteredPrices = competitorPrices
            .filter(byValidPrices) as number[];

        if (!filteredPrices.length) return 0;

        const calculateMedianPrice = () => {
            const sortedPrices = filteredPrices.sort((a, b) => b - a);
            const middleIndex = Math.floor(sortedPrices.length / 2);

            if (sortedPrices.length % 2) {
                return sortedPrices[middleIndex];
            }

            return (sortedPrices[middleIndex] + sortedPrices[middleIndex - 1]) / 2;
        };

        switch (compsetType) {
            case COMPSET_TYPE.LOW:
                return Math.max(...filteredPrices);

            case COMPSET_TYPE.HIGH:
                return Math.min(...filteredPrices);

            default:
                return calculateMedianPrice();
        }
    }

    minMaxPrices(
        data: RatesDocumentModel | null,
        days: Day[],
        excludeHotelId?: number | null,
    ): {
            minPrices: (number | null)[],
            maxPrices: (number | null)[],
        } {
        const minPrices: (number | null)[] = [];
        const maxPrices: (number | null)[] = [];

        days.forEach(day => {
            let prices: number[] = [];
            const currentHotelsRoom = this.getAllRooms(day as Day, data);

            if (currentHotelsRoom) {
                prices = Object.keys(currentHotelsRoom)
                    .filter(x => Number(x) !== excludeHotelId)
                    .map(hotelId => this.switchPrice(currentHotelsRoom[Number(hotelId)]))
                    .filter(price => price !== null && price !== PRICE.NA && price !== PRICE.SOLD_OUT) as number[];
            }

            minPrices.push(prices.length ? Math.min(...prices) : null);
            maxPrices.push(prices.length ? Math.max(...prices) : null);
        });

        return { minPrices, maxPrices };
    }

    getCardAssessment(competitionPercent: number, compset?: CompsetModel, customThresholds?: [number, number]) {
        if (!compset) return null;

        let minThreshold = null;
        let maxThreshold = null;

        if (customThresholds) {
            [minThreshold, maxThreshold] = customThresholds;
        } else {
            minThreshold = compset.minThreshold * 100;
            maxThreshold = compset.maxThreshold * 100;
        }

        if (competitionPercent === null || minThreshold === null || maxThreshold === null) {
            return null;
        }

        const percent = Number(competitionPercent!.toFixed(2)) * 100;

        switch (compset.type) {
            case COMPSET_TYPE.LOW: {
                if (percent > maxThreshold) {
                    return ASSESSMENT_TYPES.GOOD;
                }

                if (percent < minThreshold) {
                    return ASSESSMENT_TYPES.BAD;
                }

                return ASSESSMENT_TYPES.NORMAL;
            }

            case COMPSET_TYPE.HIGH:
            case COMPSET_TYPE.MEDIAN: {
                if (percent < minThreshold) {
                    return ASSESSMENT_TYPES.GOOD;
                }

                if (percent > maxThreshold) {
                    return ASSESSMENT_TYPES.BAD;
                }

                return ASSESSMENT_TYPES.NORMAL;
            }

            default: {
                return null;
            }
        }
    }

    getMedianPrice(rooms: HotelRooms) {
        // TODO: Should be moved into filters script
        const byValidPrices = (price: number | null) => true
            && price !== null
            && price !== PRICE.SOLD_OUT
            && price !== PRICE.NA;

        const prices = Object
            .values(rooms)
            .map(room => this.switchPrice(room)!)
            .filter(byValidPrices)
            .sort((a, b) => b! - a!);

        if (!prices.length) return null;

        const middleIndex = Math.floor(prices.length / 2);

        if (prices.length % 2) {
            return prices[middleIndex];
        }

        return (prices[middleIndex] + prices[middleIndex]) / 2;
    }

    getHotelLosRestriction(
        day: Day,
        hotelId: number | string,
        data: RatesUnionDocModel | null,
    ) {
        if (!data) {
            return null;
        }

        const cd = this.getCheckinDay(day, data as RatesDocumentModel);

        if (!cd) return null;
        const hotelsData = cd.hotels;

        const isHaventData = false
            || !hotelsData
            || !hotelsData[hotelId]
            || hotelsData[hotelId].losRestriction === undefined;

        if (isHaventData) {
            return null;
        }

        return hotelsData[hotelId].losRestriction || null;
    }

    isAvailablePrice(price?: number | null) {
        if (price && price !== PRICE.NA) {
            return true;
        }
        return false;
    }

    isNoData(
        day: Day,
        data: RatesDocumentModel | null,
    ): boolean {
        if (!data || !data.checkinDates) {
            return true;
        }

        const allRooms = this.getAllRooms(day, data);
        const isNoRooms = !Object.keys(allRooms).length;

        if (isNoRooms) {
            return true;
        }

        return !data.checkinDates[day];
    }

    isOutOfRange(data: RatesUnionDocModel | null) {
        if (data && data.scan.status === SCAN_STATUS.IN_PROGRESS) {
            return false;
        }

        return !data || !data.checkinDates || !Object.keys(data.checkinDates).length;
    }

    isSoldOutDay(
        hotelId: number,
        data: { [p: number]: RatesDocumentItemModel },
        priceShown?: PRICE_SHOWN,
    ) {
        if (this.isNoDataDay(data) || this.isNADay(hotelId, data)) {
            return false;
        }
        return !this.getPriceDay(hotelId, data, priceShown);
    }

    isNoDataDay(data: {[p: number]: RatesDocumentItemModel}) {
        if (!data) {
            return true;
        }

        return !Object.keys(data).length;
    }

    isNA(day: Day, hotelId: number, priceShown?: PRICE_SHOWN, doc?: RatesDocumentModel | null) {
        if (!doc) return false;
        if (this.isNoData(day, doc)) return false;

        const price = this.getPrice(day, hotelId, doc, priceShown);

        return false
            || price === PRICE.NA
            || price === null;
    }

    isNADay(
        hotelId: number,
        data: {[p: number]: RatesDocumentItemModel},
    ) {
        if (this.isNoDataDay(data)) {
            return false;
        }

        if (!data) {
            return false;
        }

        const hotelRoom = hotelId ? data[hotelId] : null;

        return hotelRoom === undefined || hotelRoom === null;
    }

    isNetTotalAvailable(
        day: Day,
        data: RatesDocumentModel | null,
    ) {
        if (!data) {
            return false;
        }

        const hotelRooms = Object.values(this.getAllRooms(day, data));

        const availableRooms = hotelRooms.filter(room => {
            if (!room.price) return false;

            const { totalPrice, netPrice } = room.price;
            const netTotalNotEqual = totalPrice !== netPrice;
            const totalValid = totalPrice && totalPrice !== PRICE.SOLD_OUT && totalPrice !== PRICE.NA;
            const netValid = netPrice && netPrice !== PRICE.SOLD_OUT && netPrice !== PRICE.NA;

            return netTotalNotEqual && totalValid && netValid;
        });

        return !!availableRooms.length;
    }

    isNetTotalPricesSame(
        day: Day,
        data: RatesDocumentModel | null,
    ) {
        if (!data) return false;

        const roomsInSale = Object.values(this.getAllRooms(day, data))
            .filter(room => {
                if (!room.price) return false;

                const { netPrice, totalPrice, shownPrice } = room.price;
                const isSoldOut = netPrice === 0 && totalPrice === 0 && shownPrice === 0;

                return !isSoldOut;
            });

        if (!roomsInSale.length) {
            return false;
        }

        return !!roomsInSale.length;
    }

    isScanAvailable(settings: RatesSettingsModel | null, day?: Day): boolean {
        if (!settings) {
            return false;
        }

        const isDisabledProvider = this.providersService.isDisabledProvider(settings.provider);

        if (isDisabledProvider) {
            return false;
        }

        return day ? !this.documentFiltersService.isPreviousDay(day) : !this.documentFiltersService.isPreviousMonth;
    }

    getScreenshot(day: Day, hotelId: number, data: RatesDocumentModel | RatesCompsetMainModel) {
        const cd = this.getCheckinDay(day, data);

        if (!cd || !cd.hotels[hotelId]) return null;

        return cd.hotels[hotelId].screenshot;
    }

    async triggerScan(settings: RatesSettingsModel, filterSettings: DocumentFiltersModel, day?: Day, retries = 0): Promise<{ scanId: string, docId: number } | null> {
        const MAX_SCAN_RETRY = 3;

        if (
            !this.isScanAvailable(settings, day)
            || filterSettings.compsetId === null || filterSettings.los === null || filterSettings.pos === null
            || settings.provider === null
        ) {
            return null;
        }

        const [startDate, endDate] = getScanRange(filterSettings, day);

        const res = await this.ratesApiService.triggerScan(filterSettings, settings, startDate, endDate);

        if (res && res.scanIds.length && res.status === 'OK') {
            if (!this.userStoreState.user?.isTestUser) {
                this.loadRemainingScans(
                    this.scanLimitation.hotelId,
                    this.scanLimitation.provider,
                    true,
                );
            }

            return {
                scanId: res.scanIds[0],
                docId: res.documentIds[0],
            };
        }

        if (retries < MAX_SCAN_RETRY) {
            return this.triggerScan(settings, filterSettings, day, retries + 1);
        }

        await this.ratesApiService.logScanFail(filterSettings, settings, startDate, endDate);

        return null;
    }

    resendScheduledReport(userLevel: 'hotel' | 'cluster', schedulerId: number) {
        return this.ratesApiService
            .resendScheduledReport(userLevel, schedulerId);
    }

    resendHTMLReport(hotelId: number, scanDate: string) {
        return this.ratesApiService
            .resendHTMLReport(hotelId, scanDate);
    }

    getOccupancy(day: Day, doc: RatesDocumentModel | null) {
        const checkinDate = this.getCheckinDay(day, doc);
        if (checkinDate?.occupancy === null || checkinDate?.occupancy === undefined) {
            return null;
        }
        return checkinDate.occupancy;
    }

    getDemand(day: Day, doc: RatesDocumentModel | null) {
        const checkinDate = this.getCheckinDay(day, doc);
        if (checkinDate?.demand === null || checkinDate?.demand === undefined) {
            return null;
        }
        return checkinDate.demand;
    }

    getHotelLink(day: Day, hotelId: number | string, doc: RatesUnionDocModel | null = null) {
        const checkinDay = this.getCheckinDay(day, doc);

        if (!checkinDay) return null;

        const isAllChannelsMode = this.isAllChannelsDocument(doc);

        if (isAllChannelsMode) {
            const cd = checkinDay as unknown as AllChannelsCheckinDay;

            if (!cd[hotelId]) return null;

            return cd[hotelId].link;
        }

        const hotel = checkinDay.hotels[+hotelId];

        return hotel ? hotel.link : null;
    }
}
